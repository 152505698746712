/* Overall container grid */
.grid__contactpage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5%;
  margin-left: 5%;
  padding: 4rem;
}

/* Adjust full width on smaller devices */
@media only screen and (max-width: 768px) {

  .grid__contactpage {
    grid-template-columns: 1fr;
    margin-left: 0;
    padding: 2rem;
  }
}
@media only screen and (max-width: 480px) {
  .subdiv11 {
    padding: 1rem; /* Reduce padding for smaller screens */
    font-size: 12px; /* Smaller font size for small devices */
  }

  .formbox1 {
    height: 45px; /* Adjust height for smaller devices */
    padding: 0.8rem; /* Adjust padding to fit the screen */
    font-size: 0.9rem; /* Reduce font size for input text */
  }
}

.subdiv11,
.subdiv2 {
  padding: 1.5rem;
  width: 100%;
  font-family: "Syne";
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5;
  color: rgb(161, 53, 53);
}

.formbox1 {
  width: 100%; /* Make the input full width */
  height: 50px;
  padding: 1rem;
  box-sizing: border-box; /* Ensure padding is included in width */
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 100px;
  margin-top: 0.5rem;
}

/* Mobile responsiveness */
@media only screen and (max-width: 768px) {
  .subdiv11, .subdiv2 {
    margin-left: 0;
    width: 100%;
  }

  .textarea {
    width: 100%;
    height: 150px;
    padding: 1rem;
  }
}

.team1 {
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin-left: 0;
  color: #7c7c7c;
}

.contact-socialmedia {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.contact-socialmedia li {
  padding: 0.5rem;
}

.submit1 {
  padding: 1rem 2rem;
  margin-left: 0;
  background: linear-gradient(98.88deg, #f05f2b 13.12%, #ec1771 119.96%);
  border-radius: 40px;
  color: white;
  border: none;
}

/* Large screen adjustment */
@media (min-width: 1920px) and (max-width: 2560px) {
  .textarea {
    width: 120%;
  }
}

/* Fine-tuning for mobile responsiveness */
@media only screen and (max-width: 480px) {
  .team1 {
    font-size: 1.4rem;
  }

  .submit1 {
    width: 100%;
    height: auto;
    padding: 1rem;
    text-align: center;
    color: white;
    margin: 0 auto;
    background: linear-gradient(98.88deg, #f05f2b 13.12%, #ec1771 119.96%);
    border-radius: 40px;
    border: none;
    font-size: 1rem; 
    box-sizing: border-box; 
    white-space: nowrap; 
  }
  

  @media only screen and (max-width: 480px) {
    .submit1 {
      font-size: 0.9rem;
      padding: 0.8rem;
    }
  }
  .subdiv11 {
    padding: 2rem;
    width: 100%; /* Ensure it takes full width on small screens */
    font-family: "Syne";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: rgb(161, 53, 53);
  }
  
}
