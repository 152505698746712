.grid__card {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 1%;
  grid-auto-rows: minmax(100px auto);
  margin-left: 3rem;
  margin-top: -20rem;
}
.Text.service {
  color: white;
  background-color: #0e828f;
}
.p {
  margin-left: 2rem;
}
.span_paragraph {
  color: var(--style, #25233a);
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.2%;
  text-transform: capitalize;
}
.Text p {
  color: var(--style, #25233a);
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.2%;
  text-transform: capitalize;
}
.Text h2 {
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin-left: 2rem;
  color: #f05f2b;
}

.color {
  width: 2rem;
}
.card {
  border-radius: 16px;
  border: 1px solid rgba(230, 232, 236, 0.5);
  background: var(--gray-00, #fff);
  box-shadow: 0px 12px 56px 0px rgba(6, 28, 61, 0.12);
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: #f8f9fa;
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-card-cap-padding-y: 1.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  /* height: var(--bs-card-height); */
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid #4876a3;
  border-radius: 5px solid;

  border-radius: var(--bs-card-border-radius);
}
.card-subtitle {
  margin-top: 3rem;
  color: var(--gray-900, #061c3d);
  text-align: center;
  font-size: 20px;
  font-family: Lexend;
  line-height: 28px;
}
.card-text {
  color: var(--gray-700, #42526b);
  text-align: center;

  font-size: 16px;
  font-family: Lexend;
  font-weight: 300;
  line-height: 24px;
}
.lernmore {
  width: 100%;
}
.card-link {
  text-align: center;
  color: #f05f2b;
  font-size: 16px;
  font-family: Lexend;
  line-height: 24px;
}

.icon1 {
  background-image: url("../../Assets/Icons.png");
  width: 3rem;
  margin-left: 8rem;
}
.card .Icon1 {
  width: 3rem;
  height: 3rem;
  align-items: center;
  margin-left: 8rem;
}
.card {
  transition: all 0.2s ease;
  cursor: pointer;
  border-radius: 100px;
  border-color: aliceblue;
  margin-left: 2rem;
  /* margin-right: 3rem; */
  margin-top: 1rem;
  width: 22rem;
  padding: 1rem;
}
.mb-6 {
  margin-left: 2rem;
  margin-right: 3rem;
  margin-top: -2rem;
  margin-bottom: 4rem;
}

.card:hover {
  box-shadow: 5px 6px 6px 2px gray;
  transform: scale(1.05);
}

@media screen and (max-width: 700px) {
  /* .card:hover {
    box-shadow: 5px 6px 6px 2px gray;
    transform: scale(0.9);
  } */
  .grid__card {
    grid-template-columns: 1fr;
    padding-left: 1rem;
    margin-left: -2rem;
    margin-top: -25rem;
  }
  .Text p {
    padding: 1rem;
  }
  .span_paragraph {
    padding: 1rem;
  }
}
