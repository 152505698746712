@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: "Proxima Nova", system-ui, sans-serif;
  }
}
.btn-custom-gradient {
  background: linear-gradient(to right, #FFEC1276, #FFF16824);
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

* > *{
  font-family: 'Poppins', sans-serif;
}
