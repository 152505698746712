.brand__container{
    display: grid;
    grid-template-columns:10% 10% 10% 10% 10% 10%;
    grid-auto-rows: minmax(150px auto);
    margin-top: 3rem;
    gap: 10%;
   
}


.headingbrand{
    margin-top: 6rem;
    color:#F05F2B;
    text-align: center;
font-size: 20px;
font-family: Syne;
font-weight: 400;
}
.span_heading{
    background: var(--0-e-828-f, #0E828F);
    color: white;
}


@media screen and (max-width: 700px) {
    .brand__container{
      grid-template-columns: 1fr;
      margin-top: 4rem;
     
    }
     .headingbrand{
        margin-top: 21rem;
     }
    
     
    .brand{
        margin-left: -1rem;
    }
    .point{
        margin-left: -1rem;
    }
    .studio{
        margin-left: -3rem;
    }
}